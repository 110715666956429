import { useState } from "react";

import { PlanPriceDiscount } from "../../../requests/planPriceDiscounts/types";
import { FormField, FormValues } from "../../../components/forms/Form/types";
import usePlanPriceDiscounts from "../../../requests/planPriceDiscounts";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import useSubscriptions from "../../../requests/subscriptions";
import { Plan, PlanPrice } from "../../../requests/plans/types";
import usePlanPrices from "../../../requests/planPrices";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import {
  getDiscountedPrice
} from "../../../requests/planPriceDiscounts/utils";
import {
  ActiveSubscription, Subscription
} from "../../../requests/subscriptions/types";
import useSubscriptionUtils, {
  hasActiveSubscription
} from "../../../requests/subscriptions/utils";
import useAuth from "../../../requests/auth";



export const usePlanPriceDetailUtils = () => {
  const { getPlanPrice } = usePlanPrices();
  const { getMySubscription } = useSubscriptions();
  const { setAppModal } = useAppModal();
  const { getTime } = useAuth();
  const { getPlanPriceDiscountByCode } = usePlanPriceDiscounts();
  const { subscribeToPlan } = useSubscriptionUtils();
  const [loading, setLoading] = useState<boolean>(true);
  const [discountLoading, setDiscountLoading] = useState<boolean>(false);
  const [planPrice, setPlanPrice] = useState<PlanPrice>();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [discountedPrice, setDiscountedPrice] = useState<number>(0);
  const [discount, setDiscount] = useState<PlanPriceDiscount | null>(null);
  const [trialDays, setTrialDays] = useState<number>();
  const [activeSubscription, setActiveSubscription] = useState<
    ActiveSubscription | null
  >(null);


  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "discountCode",
      label: "Discount Code",
      placeholder: "Enter a Discount Code",
      groupClass: "field-group"
    }
  ]

  /************************/
  /** Retrieve Plan Price */
  const retrievePlanPrice = async (id: string) => {
    setLoading(true);
    try {
      const subscription = await getMySubscription();
      const activeSubscription = hasActiveSubscription(subscription);
      setActiveSubscription(activeSubscription);
      const planPrice = await getPlanPrice(id);
      setPlanPrice(planPrice);
      getTrialDays(subscription);
      const discount = subscription?.discounts.find(
        discount => (
          discount.active &&
          discount.planPriceDiscount.planPriceId === planPrice.id
        )
      );
      if (discount) {
        setDiscount(discount.planPriceDiscount);
        const discountedAmount = getDiscountedPrice(
          planPrice.amount,
          discount.planPriceDiscount.percentage
        );
        setDiscountedPrice(discountedAmount);
      }
    } catch { }
    finally {
      setLoading(false)
    }
  }

  /*****************/
  /** Pay for Plan */
  const selectPlan = (
    e: React.MouseEvent,
    planPrice: PlanPrice,
    plan: Plan
  ) => {
    e.preventDefault();
    subscribeToPlan({
      plan,
      planPrice,
      activeSubscription,
      ...(discount ? {
        discountCode: discount.code
      } : {})
    });
  }

  /*******************/
  /** Get Trial Days */
  const getTrialDays = async (
    subscription: Subscription | null
  ) => {
    try {
      const today = await getTime();
      if (
        !subscription ||
        (!subscription.freeTrialStart || !subscription.freeTrialEnd)
      ) {
        setTrialDays(7);
        return;
      };
      if (
        subscription.freeTrialEnd &&
        (subscription.freeTrialEnd) > today
      ) {
        // Refactor date logic with date-fns
        const todayDate = new Date(today);
        const freeTrialEndDate = new Date(
          subscription.freeTrialEnd
        );
        freeTrialEndDate.setHours(0, 0, 0, 0);
        todayDate.setHours(0, 0, 0, 0);
        const diffInMilliseconds = (
          freeTrialEndDate.getTime() - todayDate.getTime()
        );
        const diffInDays = (
          Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24))
        );
        setTrialDays(diffInDays > 0 ? diffInDays : 0);
        return;
      }
    } catch { }
  }


  const youreNotBilled = () => (
    <div className="amount">
      <div className="subtitle">
        Due Today:
      </div>
      <div className="price">
        {decimalPrice(0)}
      </div>
    </div>
  );

  const youreBilledSucka = (planPrice: PlanPrice) => (
    <>
      <div className={`price ${discount ? "old-price" : "current-price"}`}>
        {currencySymbol(planPrice.currency)}
        {decimalPrice(planPrice.amount)}
      </div>
      {discount ? (
        <div className="price discounted">
          {currencySymbol(planPrice.currency)}
          {decimalPrice(discountedPrice)}
        </div>
      ) : null}
    </>
  );

  const getSubtotal = () => {
    if (!planPrice) {
      return youreNotBilled()
    } else {
      return (
        <>
          <div className="amount renews-at">
            <div className="subtitle">
              {trialDays === 7 ? (
                `${trialDays}-day free trial, then renews at:`
              ) : "Renews at:"}
            </div>
            <div className="flex center">
              {youreBilledSucka(planPrice)}
              <div className="subtitle">
                every <span>{planPrice.interval}</span>
              </div>
            </div>
          </div>
          {trialDays === 7 ? (
            youreNotBilled()
          ) : (
            <div className="amount">
              <div className="subtitle">
                Due Today:
              </div>
              {youreBilledSucka(planPrice)}
            </div>
          )}
        </>
      );
    }
  }


  const findDiscount = async () => {
    if (!planPrice) return;
    try {
      setDiscountLoading(true);
      const discount = await getPlanPriceDiscountByCode(
        formValues.discountCode
      );
      if (discount.planPriceId === planPrice.id) {
        setDiscount(discount);
        const discountedAmount = getDiscountedPrice(
          planPrice.amount,
          discount.percentage
        );
        setDiscountedPrice(discountedAmount);
        setAppModal({
          title: "Discount Applied",
          children: `Discount '${discount.code}' would be applied at checkout!`
        });
      } else {
        setAppModal({
          title: "Invalid Discount",
          children: "Discount not found!"
        });
      }
    } catch {
      setAppModal({
        title: "Invalid Discount",
        children: "Discount not found!"
      });
    }
    finally {
      setDiscountLoading(false);
    }
  }


  return {
    retrievePlanPrice,
    setFormValues,
    findDiscount,
    getSubtotal,
    selectPlan,
    setLoading,
    activeSubscription,
    discountLoading,
    loading,
    formFields,
    planPrice,
    formValues,
    trialDays,
    discount
  }
}