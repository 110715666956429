import styled from "styled-components";

import { colors, functions, resolutions, snippets } from "../../../styles";
import { planStyles } from "../PlanList/styles";



const Styles = styled.div`
  gap: 1rem;
  flex-direction: row !important;
  flex-wrap: wrap;
  &>* {
    width: unset !important;
  }
  .card {
    border-radius: 10px;
  }
  .jumbo-title {
    text-align: center;
  }

  /* Plan */
  .plan {
    ${planStyles}
    flex: 1;
    padding: 2rem 2.5rem;
    .jumbo-title {
      text-align: left;
    }
    @media(max-width: ${resolutions.tablet}) {
      padding: 2rem 2.5rem;
    }
  }

  .checkout {
    width: auto;
    gap: 0.8rem;
    @media(max-width: ${resolutions.tablet}) {
      width: 100%;
    }
    /* Subtotal */
    .subtotal {
      padding: 0;
      margin-bottom: 3rem;
      overflow: hidden;
      .jumbo-title {
        padding: 1rem;
        background-color: ${functions.rgba(colors.grey1, 1)};
        margin-bottom: 0;
      }
      .content {
        padding: 1rem 2rem;
        .amount {
          font-size: 1.5rem;
          color: ${colors.grey3};
          font-weight: 600;
          gap: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          margin: 1rem 0;
          &.renews-at {
            font-size: 1rem;
            span {
              color: ${colors.primary};
            }
          }
          .subtitle {
            color: ${colors.grey6};
            font-size: 0.8rem;
          }
          .price {
            font-size: 2rem;
            font-weight: 700;
            color: ${colors.primary};
            &.old-price {
              text-decoration: line-through;
              color: ${colors.grey6};
              font-size: 1.5rem;
            }
            &.current-price {
              color: ${colors.grey3};
              font-size: 1.5rem;
            }
            &.discounted {
              color: ${colors.grey3};
              font-size: 1.75rem;
            }
          }
        }
      }
    }

    /* Discount Form */
    .discount-form {
      .jumbo-title {
        margin-bottom: 1rem;
      }
    }

    /** Applied Discounts */
    .applied-discounts {
      margin-top: 2rem;
      .discount {
        background-color: ${functions.rgba(colors.primary, 0.1)};
        padding: 1rem;
        border-top: 7px solid ${colors.primary};
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        ${snippets.flexCenter}
        gap: 0.5rem;
        .title {
          font-size: 1.25rem;
          color: ${colors.primary};
          text-transform: uppercase;
        }
        .description {
          color: ${colors.grey6};
          font-weight: 600;
          h4 {
            color: ${colors.grey3};
          }
        }
      }
    }
  }
`;

export default Styles;